<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.external">
			<template #title>External Resources</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'ExternalResourcesNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="resources" item-key="name" :search="search" @click:row="editResource" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>

				<template v-slot:item.status="{ item }">
					<span>{{ item.status | capitalize }}</span>
				</template>

			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>

import ResourcesIndex from '@/views/resources/Index'
import {call, sync} from "vuex-pathify";

export default {
	name: "Index",
	components: {
		ResourcesIndex
	},
	computed: {
		resources: sync('external_resources/external_resources'),
	},
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Title',
				align: 'start',
				sortable: true,
				value: 'title',
			},
			{
				text: 'Status',
				align: 'start',
				sortable: true,
				value: 'status',
			},
		]
	}),
	mounted() {
		this.getExternalResources(null)
	},
	methods: {
		getExternalResources: call('external_resources/getExternal_resources'),
		editResource(data) {
			this.$router.push({name: 'ExternalResourcesEdit', params: {id: data.id}})
		},
	}
}
</script>