<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons[plural]">
			<template #title>{{ pageTitle }}</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: `${pluralCapitalized}New`}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="resources" item-key="name" :search="search" @click:row="editResource" :item-class="() => 'hover-pointer'" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>

				<template v-slot:item.status="{ item }">
					<span>{{ item.status | capitalize }}</span>
				</template>

			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import _find from "lodash/find"
import helperMixin from "@/mixins/helperMixin";

export default {
	name: "ResourcesIndex",
	props: ['type', 'plural', 'resources', 'title'],
	mixins: [helperMixin],
	computed: {
		typeCapitalized(){
			return this.type[0].toUpperCase() + this.type.substring(1)
		},
		pluralCapitalized(){
			return this.plural[0].toUpperCase() + this.plural.substring(1)
		},
		pageTitle(){
			if(this.title) return this.title
			return this.pluralCapitalized
		},
	},
	data: () => ({
		sortBy: 'published_date',
		sortDesc: true,
		search: '',
		headers: [
			{
				text: 'Title',
				align: 'start',
				sortable: true,
				value: 'title',
			},
			{
				text: 'Status',
				align: 'start',
				sortable: true,
				value: 'status',
			},
			{
				text: 'Brand',
				align: 'start',
				sortable: true,
				value: 'brand',
			},
			{
				text: 'Published date',
				align: 'start',
				sortable: true,
				value: 'published_date',
			},
			{
				text: 'Slug',
				align: 'start',
				sortable: true,
				value: 'slug',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set(`${this.plural}/get${this.pluralCapitalized}!`, null)
		},
		editResource(data) {
			this.$router.push({name: `${this.pluralCapitalized}Edit`, params: {id: data.id}})
		},
		filterOnlyCapsText(value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>